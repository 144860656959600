import { demystConsoleApi, blackfinApi } from "./api";

export const userApi = {
  async selfSignUp(formData, captchaResponse) {
    return await demystConsoleApi.post(
      "/users",
      {
        g_recaptcha_response: captchaResponse || undefined,
        user: formData.user,
        organization: formData.organization,
      },
      { withCredentials: true },
    );
  },

  async login(email, password, code, captchaResponse) {
    return await demystConsoleApi.post(
      "/users/sign_in",
      {
        g_recaptcha_response: captchaResponse || undefined,
        user: {
          email,
          password,
          code,
        },
      },
      { withCredentials: true },
    );
  },

  async switchOrganization(target_id) {
    return await demystConsoleApi.post(
      "/users/switch_organization",
      { switch: { target_id } },
      { withCredentials: true },
    );
  },

  async unlockUser(unlockToken) {
    return await demystConsoleApi.get(`/users/unlock?unlock_token=${unlockToken}`, {
      withCredentials: true,
    });
  },

  async resendUnlockInstructions(email) {
    return await demystConsoleApi.post(
      "/users/unlock",
      {
        email: email,
      },
      { withCredentials: true },
    );
  },

  async reconfirm(email, captchaResponse) {
    return await demystConsoleApi.post(
      "/users/confirmation",
      {
        g_recaptcha_response: captchaResponse || undefined,
        user: { email },
      },
      { withCredentials: true },
    );
  },

  async checkThirdPartyCookie() {
    try {
      await demystConsoleApi.get("/public/cookie_setter", {
        withCredentials: true,
      });
      await demystConsoleApi.get("/public/cookie_monster", {
        withCredentials: true,
      });
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  },

  async getAgreementHTML(agreement_type, { version } = {}) {
    return await demystConsoleApi.get(
      `/agreements/${agreement_type}${version ? `?version=${version}` : ""}`,
      { withCredentials: true },
    );
  },

  async acceptAgreement(agreement_type) {
    return await demystConsoleApi.post(
      "/users/agreements",
      { agreement: { agreement_type } },
      { withCredentials: true },
    );
  },

  async isLoginCaptchaNeeded() {
    return await demystConsoleApi.get("/captcha_sign_in", { withCredentials: true });
  },

  async isSignUpCaptchaNeeded() {
    return await demystConsoleApi.get("/captcha_sign_up", { withCredentials: true });
  },

  async isForgotPasswordCaptchaNeeded() {
    return await demystConsoleApi.get("/captcha_send_reset_password_instructions", {
      withCredentials: true,
    });
  },

  async isResetPasswordCaptchaNeeded() {
    return await demystConsoleApi.get("/captcha_reset_password", { withCredentials: true });
  },

  async isInvitationAcceptCaptchaNeeded() {
    return await demystConsoleApi.get("/captcha_invitation_accept", { withCredentials: true });
  },

  async isCaptchaResendConfirmationInstructions() {
    return await demystConsoleApi.get("/captcha_resend_confirmation_instructions", {
      withCredentials: true,
    });
  },

  async getUserData(headers) {
    return await demystConsoleApi.get(
      "/jwt/user_data",
      headers ? { withCredentials: true, headers } : { withCredentials: true },
    );
  },

  async getOrganizationActivity(params = {}) {
    return await demystConsoleApi.get("/organization/activities", {
      withCredentials: true,
      params,
    });
  },

  async getOrganizationHighlights(params = {}) {
    return await demystConsoleApi.get("/organization/usage_highlights", {
      withCredentials: true,
      params,
    });
  },

  async getOrganizationActivityParams() {
    return await demystConsoleApi.get("/organization/activities/params", { withCredentials: true });
  },

  async logoutUser() {
    return await demystConsoleApi.delete("/users/sign_out", { withCredentials: true });
  },

  async logoutAdminUserUsingAccountInClientOrganization(organization_id, password) {
    return await demystConsoleApi(`/admin/organizations/${organization_id}/sessions`, {
      method: "delete",
      data: { session: { password } },
      withCredentials: true,
    });
  },

  async getSessionHash() {
    return await demystConsoleApi.get("/session_hash", { withCredentials: true });
  },

  async sendResetPasswordEmail(email, captchaResponse) {
    return await demystConsoleApi.post(
      "/users/password",
      {
        g_recaptcha_response: captchaResponse || undefined,
        user: {
          email,
        },
      },
      { withCredentials: true },
    );
  },

  async setPassword(password, passwordConfirmation, token, captchaResponse) {
    return await demystConsoleApi.put(
      "/users/password",
      {
        g_recaptcha_response: captchaResponse || undefined,
        user: {
          password,
          passwordConfirmation,
          reset_password_token: token,
        },
      },
      { withCredentials: true },
    );
  },

  async acceptInvitation(formData, captchaResponse) {
    return await demystConsoleApi.patch(
      "/users/invitation",
      { g_recaptcha_response: captchaResponse || undefined, user: formData },
      { withCredentials: true },
    );
  },

  async sendFrontForm(payload) {
    return await demystConsoleApi.post("/public/call_to_action_submissions", payload, {
      withCredentials: true,
    });
  },

  // -----

  async getJWT() {
    return await demystConsoleApi.get("/jwt/show", {
      withCredentials: true,
    });
  },

  async getChannelInfo({ channelId }) {
    return await demystConsoleApi.get(`/channels/${channelId}?for_nemo=true`, {
      withCredentials: true,
    });
  },

  async getSingleLookup({ token, id, inputs, configuration, region }) {
    return await blackfinApi(region).post(
      "/v2/channel",
      {
        ...(configuration || {}),
        id,
        inputs,
        api_key: token,
      },
      {
        withCredentials: true,
      },
    );
  },

  async sendLookupDataToManta({ id }) {
    return await demystConsoleApi.post(
      `/events/channels/${id}/single_lookups`,
      {},
      { withCredentials: true },
    );
  },

  async sendTransactionFailedEventToManta({ id, errorMessage }) {
    return await demystConsoleApi.post(
      `/events/channels/${id}/transactions`,
      { error_message: errorMessage },
      { withCredentials: true },
    );
  },

  async changeExpiredPassword({ current_password, password, password_confirmation }) {
    return await demystConsoleApi.put(
      "/users/password_expired",
      { user: { current_password, password, password_confirmation } },
      { withCredentials: true },
    );
  },

  async changePassword({ current_password, password, password_confirmation }) {
    return await demystConsoleApi.put(
      "/users.json",
      { user: { current_password, password, password_confirmation } },
      { withCredentials: true },
    );
  },
};
